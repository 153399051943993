import React from 'react';
import { Router } from '@reach/router';
import queryString from 'query-string';

import OnError from 'views/On/Error';
import OnSuccess from 'views/On/Success';

const OnApp = ({ location }) => {
  const parsed = queryString.parse(location.search);

  const { status, region } = parsed;
  if (!status || !region) {
    return null;
  }
  if (status === 'success') {
    return <OnSuccess region={region} />;
  }
  if (status === 'error') {
    return <OnError region={region} />;
  }
};

const MyRouter = () => {
  return (
    <Router>
      <OnApp default />
    </Router>
  );
};
export default MyRouter;
