import React from 'react';
import { FormattedMessage, navigate } from 'gatsby-plugin-intl';
// nodejs library that concatenates classes
// @material-ui/core components
import { makeStyles } from '@material-ui/core/styles';
// import Button from '@material-ui/core/Button';

import { onLogoutStart } from '@allconnect/store/lib/user/user.actions';
import { paymentReset } from '@allconnect/store/lib/payment/payment.actions';
import { connect } from 'react-redux';
import { Parallax } from '@jackywxd/shared-material-ui';
import landingPageStyle from 'assets/jss/material-kit-react/views/landingPage';
import Layout from 'components/Layout';
// Sections for this page
import Button from 'components/CustomButtons';
// const dashboardRoutes = [];

const useStyles = makeStyles(landingPageStyle);

const OnError = ({ region }) => {
  const classes = useStyles();
  return (
    <Layout>
      <Parallax filter style={{ background: 'rgba(66, 133, 243, 0.6)' }}>
        <div className={classes.container}>
          <h2>
            <FormattedMessage
              id="on.error.message"
              defaultMessage="Sorry, something wrong with your connection to {region}."
              values={{ region }}
            />
          </h2>
          <h4>
            <FormattedMessage
              id="on.error.try"
              defaultMessage="Please try other region or register free account."
            />
          </h4>
          <br />
          <br />
          <Button
            size="lg"
            round
            color="google"
            onClick={() => {
              navigate('/register');
            }}
          >
            <FormattedMessage id="button.registerFreeAccount" />
          </Button>
        </div>
      </Parallax>
    </Layout>
  );
};

const mapDispatchToProps = dispatch => ({
  signoutStart: () => dispatch(onLogoutStart()),
  paymentReset: () => dispatch(paymentReset()),
});

export default connect(null, mapDispatchToProps)(OnError);
